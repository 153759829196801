import { Normalizer } from '../utils/Normalizer';
import { GTM_ERROR_CODES } from '../error-codes';

(() => {
    document.addEventListener('Checkout_Payment_Complete', (e) => {
        const dataset = document.getElementById('gtm-add-payment-info');
        const json = JSON.parse(dataset.dataset.gtmJson);

        json.ecommerce.payment_type = Normalizer.normalize(e.detail.paymentMethodCode);

        window.dataLayerPushDeferred({ ecommerce: null });
        window.dataLayerPushDeferred(json);
    });

    document.addEventListener('Checkout_Payment_SelectMethod', (e) => {
        const { order } = e.detail;
        const { paymentMethod } = e.detail;

        if (order.scheduled_delivery_date && paymentMethod === 'bank_transfer') {
            window.dataLayerPushDeferred({
                event: 'error',
                error_id: GTM_ERROR_CODES.WARNING_SCHEDULED_DELIVERY_NOT_ALLOWED,
            });
        }
    });

    document.addEventListener('Checkout_Payment_Error', () => {
        window.dataLayerPushDeferred({
            event: 'error',
            error_id: GTM_ERROR_CODES.ERROR_FORM_CHECKOUT_PAYMENT,
        });
    });
})();
